/**
 * Application entry point
 */
require("babel-core/register");
// Load application styles

// ================================
// START YOUR APP HERE
// ================================

const flkty = require('flickity');

const classAdder = require('chunks/class-adder');
const fillFeatured = require('featured');
/**
 * Header slider
 */
window.onload = () => {

    /**
     * Item Slider Init
     */
    (() => {
      if (document.querySelector('.item-slider')) {

        const slider = new flkty('.item-slider', {
          cellAlign: 'center',
          wrapAround: true,
          adaptiveHeight: true,
          percentPosition: true  

        });
        const goBackButton = document.body.appendChild(document.createElement('div'));
        goBackButton.classList.add('go-back-btn');
        goBackButton.addEventListener('click', () => history.back());
      }
    })();

    (() => {
      if (document.getElementById('offers-slider')) {
        const slider = new flkty('.offers-slider', {
          cellAlign: 'center',
          wrapAround: true,
          autoPlay: 5000,          
        });
      }
    })()
  

    if (document.getElementById('preload')) {
      let preload = document.getElementById('preload');
      preload.style.opacity = "0";
      setTimeout(() => preload.remove(), 300);
      document.body.classList.remove('preloading');
    }

    /**
     * Load lazy-loaded images
     */
    const imgs = document.querySelectorAll('img[data-ll-src]');
    if (imgs.length) {
      imgs.forEach(img => img.src = img.getAttribute('data-ll-src'));
    }
}

/**
 * Navigation Active Class
 */
(() => {
  const activeNavIndex = Number(document.querySelector('.hidden--nav-index').textContent.trim());
  const navItems = document.querySelectorAll('.header .nav a');
  navItems[activeNavIndex].classList.add('active');
})();

/**
 * Mobile Navigation 
 */
(() => {
  const menuButton = document.querySelector('#menu-button');

  document.body.addEventListener('click', e => {
    if (e.target.getAttribute('data-menu') === 'open') {
      e.preventDefault();
      changeAttributes('close');
      toggleMenu(true);
      menuButton.classList.toggle('on-menu');
    } else if (e.target.getAttribute('data-menu') === 'close') {
      e.preventDefault();
      changeAttributes('open');
      toggleMenu(false);
      menuButton.classList.toggle('on-menu');
    }
  });

  function changeAttributes(attribute) {
    const needed = document.querySelectorAll('[data-menu]');
    needed.forEach(elem => elem.setAttribute('data-menu', attribute));
  }

  function toggleMenu(state) {
    const nav = document.querySelector('.mobile-nav');
    state ? nav.classList.add('on-menu') : nav.classList.remove('on-menu');
  }
})();

/**
 * Fill Featured
 */
(() => {
  if (location.pathname === '/' || location.pathname === '/index.html') {
    fillFeatured();
  }
})();
